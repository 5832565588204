import "./reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import axios from "axios";
import emailjs from "@emailjs/browser";
import cheerio from "cheerio";
//hooks
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
// component
import LoginInput from "./components/LoginInput";

export default function App() {
  let authTime = 180;

  let loginInputClick = useSelector((state) => state.loginInputClick);
  let loginInput = useSelector((state) => state.loginInput);
  let account = useSelector((state) => state.account);

  const form = useRef(null);

  const [userId, setUserId] = useState(false);
  const [userPw, setUserPw] = useState(false);
  const [signFail, setSignFail] = useState(false);
  const [signOk, setSignOk] = useState(false);
  const [userMail, setUserMail] = useState(false);
  const [authCode, setAuthCode] = useState(false);
  const [isMailSend, setIsMailSend] = useState(false);
  const [mailSending, setMailSending] = useState(false);
  const [authFail, setAuthFail] = useState(false);
  const [authOk, setAuthOk] = useState(false);
  const [time, setTime] = useState(authTime);
  const [min, setMin] = useState("");
  const [sec, setSec] = useState("");
  const [timeOut, setTimeOut] = useState(false);
  const [reSend, setReSend] = useState(false);
  const [notice, setNotice] = useState(false);

  const generateVerificationCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
    const charactersLength = characters.length;
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setAuthCode(result);
  };

  useEffect(async () => {
    await axios.post(
      `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/auth/token`
    );
    await axios
      .get(`${process.env.REACT_APP_ASPERA_SHARES_SERVER}/login`)
      .then((res) => {
        const $ = cheerio.load(res.data);
        const asperaNotice = $("div.announcement").html();
        setNotice(asperaNotice);
      });
    await axios.post(
      `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/auth/tokendel`
    );
  }, []);

  const login = async () => {
    await axios.post(
      `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/auth/tokendel`
    );
    await axios.post(
      `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/auth/token`
    );
    await axios
      .post(`${process.env.REACT_APP_ASPERA_SHARES_SERVER}/login`, {
        username: account.id,
        password: account.pw,
      })
      .then((res) => {
        const $ = cheerio.load(res.data);
        const loginVerify = $("li.menuitem").attr("class");
        if (loginVerify === undefined) {
          setSignFail(true);
        } else {
          setUserId(account.id);
          setUserPw(account.pw);
          setSignFail(false);
          setSignOk(true);
          axios
            .get(
              `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/api/v1/data/users`,
              {
                auth: {
                  username: process.env.REACT_APP_ASPERA_SHARES_UESRNAME,
                  password: process.env.REACT_APP_ASPERA_SHARES_PASSWORD,
                },
              }
            )
            .then((res) => {
              res.data.map((user) => {
                if (user.username === account.id) {
                  setUserMail(user.email);
                  generateVerificationCode();
                }
              });
            });
        }
      });
    await axios.post(
      `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/auth/tokendel`
    );
  };

  const sendMail = () => {
    setMailSending(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_API_KEY
      )
      .then((result) => {
        alert(`${userMail}로 메일 전송이 완료되었습니다.`);
        setMailSending(false);
        setIsMailSend(true);
        setTimeOut(false);
        setTime(authTime);
      })
      .catch((error) => {
        console.log(error.text);
        alert("메일을 전송이 실패했습니다.");
      });
  };

  const reSendMail = () => {
    generateVerificationCode();
    setReSend(true);
  };

  useEffect(() => {
    if (reSend) {
      console.log(authCode);
      sendMail();
      setReSend(false);
    }
  }, [reSend]);

  const verifyCode = () => {
    if (timeOut) {
      alert("입력시간이 만료되었습니다.");
    } else {
      if (authCode === account.code) {
        setAuthFail(false);
        setAuthOk(true);
      } else {
        setAuthFail(true);
      }
    }
  };

  const sharesLogin = async () => {
    console.log("1233", process.env.REACT_APP_ASPERA_SHARES_SERVER);
    await axios.post(
      `${process.env.REACT_APP_ASPERA_SHARES_SERVER}/auth/token`
    );
    await axios
      .post(`${process.env.REACT_APP_ASPERA_SHARES_SERVER}/login`, {
        username: userId,
        password: userPw,
      })
      .then(() => {
        window.location = process.env.REACT_APP_ASPERA_SHARES_SERVER;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (time > 0) {
      setMin(String(parseInt(time / 60)).padStart(2, "0"));
      setSec(String(parseInt(time % 60)).padStart(2, "0"));
    }
    if (time <= 0) {
      setTimeOut(true);
      console.log(timeOut);
    }
  }, [time]);

  return (
    <section className="bg">
      <div className="container">
        <div className="login_wrap">
          <div className="logo">
            <img src="img/knpeople_logo.png" />
          </div>
          <div className="login_box box_shadow">
            {loginInput.map((a, i) => {
              if (i < 2) {
                return (
                  <LoginInput
                    style={`${a.css} ${
                      loginInputClick == i ? "select_login_input" : ""
                    }`}
                    select={i}
                    placeHolder={loginInput[i].placeholder}
                    key={i}
                  />
                );
              }
            })}
            {signFail && (
              <div className="fail_text">
                아이디 또는 비밀번호가 틀렸습니다.
              </div>
            )}

            {signOk && (
              <>
                <div className="d-flex auth_wrap">
                  <LoginInput
                    style={`${loginInput[2].css} ${
                      loginInputClick == 2 ? "select_login_input" : ""
                    }`}
                    select={loginInput[2].select}
                    placeHolder={`${loginInput[2].placeholder}`}
                  />
                  {authOk ? null : isMailSend ? (
                    <button className={`authBtn radius`} onClick={verifyCode}>
                      인증하기
                    </button>
                  ) : mailSending ? (
                    <button className={`authBtn radius btn_disable`}>
                      전송중
                    </button>
                  ) : (
                    <button
                      className={`authBtn radius`}
                      onClick={() => {
                        sendMail();
                        console.log(authCode);
                      }}
                    >
                      인증번호 전송
                    </button>
                  )}
                </div>

                {authOk
                  ? null
                  : isMailSend && (
                      <div className="timer_box">
                        <div className="timer">
                          {timeOut ? (
                            <span className="auth_expire" onClick={() => {}}>
                              인증시간 만료
                            </span>
                          ) : (
                            <span>{`${min}:${sec}`}</span>
                          )}
                        </div>
                        {mailSending ? (
                          <div>전송중</div>
                        ) : (
                          <div onClick={reSendMail} className="resend">
                            재전송
                          </div>
                        )}
                      </div>
                    )}
                {authFail && (
                  <div className="fail_text">인증번호가 틀렸습니다.</div>
                )}
              </>
            )}

            {signOk ? null : (
              <button className="loginBtn radius" onClick={() => login()}>
                로그인
              </button>
            )}
            {authOk && (
              <button className="loginBtn radius" onClick={sharesLogin}>
                Aspera Shares Login
              </button>
            )}
          </div>
	  {/*<div
            className="notice_Box"
            dangerouslySetInnerHTML={{ __html: notice }}
          />*/}
        </div>
      </div>

      <form ref={form} className="mail_form">
        <input name="code" value={authCode} />
        <input name="usermail" value={userMail} />
      </form>
    </section>
  );
}
