import { useSelector, useDispatch } from "react-redux";
import { changeClick, changeId, changePw, changeCode } from "./../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Login_input(props) {
  let loginInput = useSelector((state) => state.loginInput);
  let dispatch = useDispatch();
  return (
    <div
      className={`account_input ${props.style}`}
      data-select={props.select}
      onClick={(e) => dispatch(changeClick(e.currentTarget.dataset.select))}
    >
      <span className="input_icon">
        <FontAwesomeIcon icon={loginInput[props.select].icon} />
      </span>
      <input
        type={loginInput[props.select].type}
        className="input_box"
        data-select={props.select}
        onSelect={(e) => dispatch(changeClick(e.currentTarget.dataset.select))}
        placeholder={props.placeHolder}
        onChange={(e) => {
          if (loginInput[props.select].name === "아이디") {
            dispatch(changeId(e.target.value));
          }
          if (loginInput[props.select].name === "비밀번호") {
            dispatch(changePw(e.target.value));
          }
          if (loginInput[props.select].name === "이메일인증") {
            dispatch(changeCode(e.target.value));
          }
        }}
      />
    </div>
  );
}
