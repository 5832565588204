import { configureStore, createSlice } from "@reduxjs/toolkit";
import { faUser, faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";

let loginInputClick = createSlice({
  name: "loginInputClick",
  initialState: null,
  reducers: {
    changeClick(state, i) {
      return i.payload;
    },
  },
});

let loginInput = createSlice({
  name: "loginInput",
  initialState: [
    {
      name: "아이디",
      select: 0,
      css: "input_top",
      placeholder: "아이디",
      icon: faUser,
      type: "text",
    },
    {
      name: "비밀번호",
      select: 1,
      css: "input_bottom box_shadow",
      placeholder: "비밀번호",
      icon: faLock,
      type: "password",
    },
    {
      name: "이메일인증",
      select: 2,
      css: "radius auth_input",
      placeholder: "인증번호",
      icon: faEnvelope,
      type: "text",
    },
  ],
});

let account = createSlice({
  name: "account",
  initialState: { id: "", pw: "", code: "" },
  reducers: {
    changeId(state, id) {
      state.id = id.payload;
    },
    changePw(state, pw) {
      state.pw = pw.payload;
    },
    changeCode(state, code) {
      state.code = code.payload;
    },
  },
});

export let { changeClick } = loginInputClick.actions;
export let { changeId, changePw, changeCode } = account.actions;

export default configureStore({
  reducer: {
    loginInputClick: loginInputClick.reducer,
    loginInput: loginInput.reducer,
    account: account.reducer,
  },
});
